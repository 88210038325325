import { ApolloClient, InMemoryCache } from '@apollo/client';
import * as AbsintheSocket from "@absinthe/socket";
import {createAbsintheSocketLink} from "@absinthe/socket-apollo-link";
import {Socket as PhoenixSocket} from "phoenix";

const absintheSocketLink = process.browser && createAbsintheSocketLink(AbsintheSocket.create(
    new PhoenixSocket(process.env.NEXT_PUBLIC_ADMIN_GATEWAY_SUBSCRIPTION_ENDPOINT)
)); 

export const client = new ApolloClient({
    link: absintheSocketLink,
    cache: new InMemoryCache()
});
